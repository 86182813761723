<template>
  <div>
    <Modal :options="{width: '30vw',esc:false,type:'info'}">
      <form @submit.prevent="ceder_solicitud">
        <div class="title">Ceder solicitud</div>
        <div class="body">
          <div class="row form_group">
            <label for="alianza" class="col-form-label col-sm-12 col-lg-3">Alianza a ceder</label>
            <div class="col-sm-12 col-lg-9">
              <select v-model="alianza_id" name="alianza" id="alianza" class="form-control">
                <option v-for="alianza in alianzas" :value="alianza.id">{{ alianza.nombre }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Ceder</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/analyticpoint/api/comite/cesiones';
import apiAlianzas from '@/apps/analyticpoint/api/comite/alianzas';

export default {
  components: {
    Modal
  },
  props: {
    solicitud: {
      type: Object
    },
    recesion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      alianzas: [],
      alianza_id: null
    }
  },
  mounted() {
    this.cargar_alianzas();
  },
  methods: {
    async cargar_alianzas() {
      try {
        this.alianzas = (await apiAlianzas.obtener_alianzas_activas()).data;
        this.alianza_id = this.alianzas[0].id;
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async ceder_solicitud() {
      try {
        let res = (await api.ceder_solicitud({
          solicitud_id: this.solicitud.id,
          alianza_id: this.alianza_id,
          recesion: this.recesion
        })).data;

        this.$log.info('res', res);
        this.$emit('close', 1);
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>