import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_ANALYTICPOINT+'/alianzas');

export default {
  obtener_alianzas() {
    return http.get(`/`);
  },

  obtener_alianzas_activas() {
    return http.get(`/?f[0][column]=estatus&f[0][operator]=equal_to&f[0][query_1]=activo`);
  },

  obtener_alianza(id) {
    return http.get(`/${id}`);
  },

  crear_alianza(payload) {
    return http.post(`/`, payload);
  },

  actualizar_alianza(id, payload) {
    return http.put(`/${id}`, payload);
  },

  obtener_usuarios_account() {
    return http.get(`/usuarios/account`);
  }
}
