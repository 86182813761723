<template>
  <div>
    <Modal :options="{width: '80vw',close:true}" @close="$emit('close')">
      <div class="title">Solicitud cedida</div>
      <div class="body">
        <Visualizador :solicitud_id="solicitud_id" tipo_visualizacion="resumen" @solicitud="cargar_solicitud" />
      </div>
      <div v-if="cesion.estatus == 'pendiente' || cesion.resolucion == 'rechazado'" class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <template v-if="cesion.estatus == 'pendiente'">
            <button class="btn btn-primary mr-2" @click="resolucion_tipo='aceptado'">Aceptar solictud</button>
            <button class="btn btn-danger" @click="resolucion_tipo='rechazado'">Rechazar solicitud</button>
            </template>
            <template v-else-if="cesion.resolucion == 'rechazado'">
            <button class="btn btn-primary" @click="volver_a_ceder=true">Volver a ceder </button>
            </template>
          </div>
        </div>
      </div>
    </Modal>

    <Resolucion v-if="resolucion_tipo" :cesion_id="cesion.id" :solicitud="solicitud" :tipo="resolucion_tipo" @close="resolucion_tipo=null" @finalizar="finalizar_solicitud" />
    <Ceder v-if="volver_a_ceder" :solicitud="solicitud" @close="cerrar_cesion" />
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import Visualizador from '@/apps/analyticpoint/pages/Solicitud/Visualizador';
import Resolucion from './Resolucion';
import Ceder from '@/apps/analyticpoint/pages/Cesion/Info/Ceder';

export default {
  components: {
    Modal, Visualizador, Resolucion, Ceder
  },
  props: {
    cesion: {
      type: Object
    },
    solicitud_id: {
      type: String
    }
  },
  data() {
    return {
      resolucion_tipo: null,
      solicitud: null,
      volver_a_ceder: false
    }
  },
  methods: {
    cargar_solicitud(solicitud) {
      this.solicitud = solicitud;
    },
    finalizar_solicitud() {
      this.resolucion_tipo=null;
      this.$emit('close');
    },
    cerrar_cesion() {
      this.volver_a_ceder=false;
    }
  }
}
</script>